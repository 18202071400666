document.addEventListener("DOMContentLoaded", function() {
    (function() {
        const lightningLocator = document.getElementById('lightningLocator');
        if (!lightningLocator) return;

        const loadSFScriptTimeout = setTimeout(loadSFScript, 1000);

        function loadSFScript() {
            clearTimeout(loadSFScriptTimeout);
            const hostname = window.location.hostname.toLowerCase();
            let src = "https://apco-portal.my.salesforce-sites.com/lightning/lightning.out.js";
            //let src = "https://apco-portal--sit.sandbox.lightning.force.com/lightning/lightning.out.js";
            const sfs = document.createElement("script");
            sfs.setAttribute("src", src);
            document.body.appendChild(sfs);
        }

        const urlParams = new URLSearchParams(window.location.search);
        const flowName = "SF_EmailPreferenceCentre"; //urlParams.get('flowName');

        if (flowName) {
            const loadSFFormTimeout = setTimeout(loadSFForm, 5000);

            function loadSFForm() {
                clearTimeout(loadSFFormTimeout);

                $Lightning.use("c:GenericEmbedFlowInExternalWebsitesApp", function () {
                    let parameters = "flowName=" + flowName;

                    $Lightning.createComponent(
                        "c:GenericEmbedFlowInExternalWebsites",
                        { "parameters": parameters },
                        "lightningLocator",
                        function (cmp) { }
                    );

                },
                'https://apco-portal.my.salesforce-sites.com/APCOWebToCase', 'authToken');
            }
        }
    })();
});
